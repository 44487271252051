@font-face {
  font-family: "neueMachina";
  src: url("./font-nakamoto/PPNeueMachina-InktrapRegular.woff2") format("woff2"),
    url("./font-nakamoto/PPNeueMachina-InktrapRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "neueMachinaSemiBold";
  src: url("./font-nakamoto/PPNeueMachina-InktrapSemibold.woff2")
      format("woff2"),
    url("./font-nakamoto/PPNeueMachina-InktrapSemibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "neueMachinaBold";
  src: url("./font-nakamoto/PPNeueMachina-InktrapBold.woff2") format("woff2"),
    url("./font-nakamoto/PPNeueMachina-InktrapBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "digital-7";
  src: url("./font-nakamoto/digital-7.ttf") format("woff2"),
    url("./font-nakamoto/digital-7.ttf") format("woff");
}

@font-face {
  font-family: "mondwest";
  src: url("./font-nakamoto/PPMondwest-Regular.woff") format("woff"),
    url("./font-nakamoto/PPMondwest-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Digital";
  src: url("./font-nakamoto/digital-7.ttf") format("TrueType");
}

body {
  font-family: "neueMachina", "neueMachinaSemiBold", "neueMachinaBold",
    "digital-7", "mondwest", "Digital", sans-serif;
}
